import {
  // BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import { Row } from "react-bootstrap";
import CheckoutForm from "./CheckoutForm";
import "./Experts.scss";
import { Component } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ExpertInfo from "./ExpertInfo";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

class Experts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      experts: [],
      categories: [],
      loading: true,
      buyModal: false,
      selectedPackage: {},
      errors: {},
      isDataLoaded: false
    };
  }
  async componentDidMount() {
    window.twttr.widgets.load();
    // Fetching Experts
    // let expertsDetails = [];
    // await fetchExperts().then((res) => {res.forEach((expert, id) => (expertsDetails[id] = expert))});
    // this.setState({ experts: expertsDetails });
  }

  componentDidUpdate(prevProps, prevState) {
     if (prevProps?.experts?.length > 0 && !this.state.isDataLoaded) {
        const anchor = window.location.hash.slice(1);
        if (anchor) {
          //setTimeout(() => {
            const anchorEl = document.getElementById(anchor);
            if (anchorEl) {
              anchorEl.scrollIntoView();
            }  
          //}, 100);
          
        }
        this.setState({ isDataLoaded: true });
     }
  }

  selectPackage = (selection, expert) => {
    selection.expert = expert;
    this.setState({ selectedPackage: selection, buyModal: true });
  };
  setBuyModal = () => {
    this.setState({ buyModal: !this.state.buyModal });
  };


  render() {
    const { errors, selectedPackage, buyModal, loadingPurchase } = this.state;
    return (
      <div>
        <div className={`modal-bg${buyModal ? "" : " hidden"}`}>
          <div className="modal-box" onClick={(e) => e.stopPropagation()}>
            {loadingPurchase ? (
              ""
            ) : (
              <div className="modal-x" onClick={this.setBuyModal}>
                X
              </div>
            )}
            <div className="modal-data">
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  packageSubscription={selectedPackage}
                  errors={errors}
                  setErrors={(errors) => this.setState({ errors })}
                  loading={loadingPurchase}
                  setLoading={(loadingPurchase) =>
                    this.setState({ loadingPurchase })
                  }
                  callback={() => this.setState({ buyModal: false })}
                  buyModal={buyModal}
                />
              </Elements>
            </div>
          </div>
        </div>
        <div id="experts" className="mt-5">
          <Row>
            {this.props.experts?.map((expert, id) => (
              <div className="d-inline-flex" key={id}>
                <a
                  href={"#" + expert?.username.replace(/\s/g, "-")}
                  className="expert-link mr-4"
                >
                  {expert?.username}
                </a>
                {/* <Nav variant="taps" className="flex-column pt-2">
                  <Link
                    className="expert-link mr-4"
                    to={
                      "/experts/" +
                      expert?.username.replace(/\s+/g, "-").toLowerCase()
                    }
                  >
                    {expert?.username}
                  </Link>
                </Nav> */}
              </div>
            ))}

            <div className="d-inline-flex ml-auto">
              <div className="d-flex justify-content-end white meet-the-team h1 pr-5">
                <h1 className="dark-yellow">MEET OUR TEAM OF EXPERTS</h1>
              </div>
            </div>
          </Row>

          {this.props.experts ? (
            <div>
              <Switch>
                <Route exact path="/experts/">
                  <Row>
                    <div className="col-12 white">
                      <ExpertInfo
                        experts={this.props.experts}
                        categories={this.props.categories}
                        selectPackage={this.selectPackage}
                        recentVideos={this.props.recentVideos}
                        recommendedVideos={this.props.recommendedVideos}
                      />
                    </div>
                  </Row>
                </Route>
                <Route path="/experts/:expertId">
                  <Row>
                    <div className="col-12 white">
                      <ExpertInfo
                        experts={this.props.experts}
                        categories={this.props.categories}
                        selectPackage={this.selectPackage}
                        recentVideos={this.props.recentVideos}
                        recommendedVideos={this.props.recommendedVideos}
                      />
                    </div>
                  </Row>
                </Route>
              </Switch>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Experts);
