import { Link, withRouter } from "react-router-dom";
import "./Post.scss";
function Post(props) {
  const videoId = props.data?.uri?.substr(props.data.uri.lastIndexOf("/") + 1);
  return videoId ? (
    <Link to={"/videos/" + videoId}>
      <div className="Post">
        <div className="gradiant"></div>

        <img
          src={
            props.data?.pictures?.sizes?.find((pic) => pic.width === 295)?.link
          }
          className="img-fluid"
          width="100%"
          alt=""
        />

        <div className="Post-Text">
          {/* <h3>{props.data.tags?.join(",")}</h3> */}
          <h1>{props.data?.name?.substr(0, 25) + "..."}</h1>
          <h2>{props.data?.description?.substr(0, 50) + "..."}</h2>
        </div>
      </div>
    </Link>
  ) : (
    <div></div>
  );
}
export default withRouter(Post);
