import { Link } from "react-router-dom";
import "./Blogs.scss";
import StayUpdated from "../StayUpdated/StayUpdated";
import Ad from "../Ad/Ad";
import { Component, Fragment } from "react";

export default class Blogs extends Component {
  async componentDidMount() {
    window.scrollTo(0, 0);

  }

  render() {
    //loop Affiliate Ads
    let affiliateAds = [];
    for (let i = 0; i < 20; i++) {
      affiliateAds.push(<Ad type="affiliate" position={i} ads={this.props.ads} />)
    }
    const blogsContent = (
      <Fragment>
        {this.props.selectedBlogs?.map((blog, id) => (
          <div key={id}>
            <Link
              to={
                "/blog/" +
                encodeURIComponent(
                  blog.header
                    .replace(/\s+/g, "-")
                    .replace(/[^a-zA-Z0-9-_]/g, "")
                )
              }
              className="readmore"
              key={id}
            >
              <div className="container col-12">
                <div className="row justify-content-center pb-4">
                  <img
                    src={blog.image?.url}
                    className="img-fluid blogs-image"
                    alt=""
                  />
                </div>
                <div className="row pt-1">
                  <h1 className="blogs-title">
                    <b>{blog.header}</b>
                  </h1>
                </div>
                {/* Category Name */}
                <div className="row white pt-1">
                  <h6 className="blog-metadata">{blog.category?.name}</h6>
                </div>
                {/* Blog Description */}
                <div
                  className="row text-wrap white pt-1"
                  dangerouslySetInnerHTML={{
                    __html: `${blog.text.substr(0, 400)}...`,
                  }}
                ></div>
                {/* ReadMore Button*/}
                <div className="row pt-1 readmore">
                  <b>
                    READ MORE <span className="red xx-large">.</span>
                  </b>
                </div>
              </div>
            </Link>

            {id % 2 && id !== 0 ? (
              <div className="mt-4 mb-4 text-center">
                <Ad type="leaderboard" ads={this.props.ads} />
              </div>
            ) : (
                <div></div>
              )}
          </div>
        ))}
      </Fragment>
    );

    if (this.props.fullContent)
      return (
        <div className="row">
          <div className="col-lg-8 pb-5 vertical-line">{blogsContent}</div>
          <div className="col-4 DisableOnMobile">
            <div className="col-12">

              <h2 className="dark-yellow">Get In The Game</h2>

            </div>
            {affiliateAds}
            <div className="mt-3 mb-3">
              <StayUpdated content={this.props.blogs} />
            </div>
            <div className="col-12">
              <Ad type="skyscraper" ads={this.props.ads} />
            </div>
          </div>
        </div>
      );

    return (
      <div className="row">
        <div className="col-12 ">{blogsContent}</div>
      </div>
    );
  }
}
