import { withRouter } from "react-router-dom";
import StayUpdated from "./StayUpdated/StayUpdated";
import Ad from "./Ad/Ad";
import { Component } from "react";
import { fetchBlogByTitle } from "../fetchService";
import Blogs from "./Blogs/Blogs";

class ExpandedBlog extends Component {
  constructor(props) {
    super(props);
    this.state = { blog: null };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    let blogId = this.props.match?.params?.blogId;
    blogId = decodeURIComponent(blogId.replace(/-/g, " ")).toLowerCase();
    fetchBlogByTitle(blogId).then((res) => {
      this.setState({ blog: res });
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      window.scrollTo(0, 0);
      window.location.reload();
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-10 screen-height overflow-auto vertical-line">
          {createBlog(this.state.blog)}
          <div className="pt-5">
            {this.state.blog ? (
              <Blogs
                fullContent={false}
                categories={this.props.categories}
                selectedBlogs={this.props.blogs}
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className="col-2">
          <div className="mb-3">
            <StayUpdated content={this.props.blogs} />
          </div>
          <div className="col-12">
            <Ad type="skyscraper" ads={this.props.ads} />
          </div>
        </div>
      </div>
    );
  }
}

function createBlog(blog) {
  return blog ? (
    <div className="container col-12  ">
      <div className="row justify-content-center pb-4 ">
        <img src={blog.image?.url} className="blogs-image img-fluid " alt="" />
      </div>
      <div></div>
      <div className="row  pt-1">
        <h1 className="blog-title orange">
          <b>{blog.header}</b>
        </h1>
      </div>
      <div className="row white pt-1">
        <h6 className="blog-metadata">{blog.category?.name}</h6>
      </div>
      <div
        className="row text-wrap white pt-1"
        dangerouslySetInnerHTML={{ __html: blog.text }}
      ></div>
      <div className=" row pt-5 expanded-blog-border"></div>
    </div>
  ) : (
    <div></div>
  );
}

export default withRouter(ExpandedBlog);
