export const Posts = [
  {
    id: 1,
    img: "/assets/images/test.jpg",
    tags: "Meta-data tags",
    description: "Post description should go here and the content if felixable",
    name: "POST TITLE",
    videoId:"498077164"
    
  },
  {
    id: 2,
    img: "/assets/images/test.jpg",
    tags: "Meta-data tags",
    description: "Post description should go here and the content if felixable",
    name: "POST TITLE",
    videoId:"498077164"
  },
  {
    id: 3,
    img: "/assets/images/test.jpg",
    tags: "Meta-data tags",
    description: "Post description should go here and the content if felixable",
    name: "POST TITLE",
    videoId:"498077164"
  },
  {
    id: 4,
    img: "/assets/images/test.jpg",
    tags: "Meta-data tags",
    description: "Post description should go here and the content if felixable",
    name: "POST TITLE",
    videoId:"498077164"
  },
];
