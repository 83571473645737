import { Component } from "react";
import { Link } from "react-router-dom";
import Divider from "../Divider/Divider";
import "./StayUpdated.scss";

export default class StayUpdated extends Component {
  constructor() {
    super();
    this.state = { open: false, method: "slice(0,10).map" };
  }

  handleClose() {
    this.setState({ open: false });
  }
  handleOpen() {
    this.setState({ open: true, method: "map" });
  }

  render() {
    const recomendedBlogs = (
      <div>
        {/* <Link to={"/blog/" + blog.id} className="col-12 text-overflow"> */}
        {this.state.open
          ? this.props.content?.map((blog, id) => (
              <>
                <RenderLinks id={id} blog={blog} />
              </>
            ))
          : this.props.content?.slice(0, 10).map((blog, id) => (
              <>
                <RenderLinks id={id} blog={blog} />
              </>
            ))}
      </div>
    );

    return (
      <div>
        <Divider text={"STAY UPDATED"}></Divider>
        <div className="pb-3">{recomendedBlogs}</div>
        <Divider text={""}></Divider>
        {!this.state.open ? (
          <button onClick={() => this.handleOpen()}>Read More... </button>
        ) : (
          <button onClick={() => this.handleClose()}>Read Less... </button>
        )}
      </div>
    );
  }
}

const RenderLinks = (props) => {
  return (
    <div className="row small-font pt-2 StayUpdated" key={props.id}>
      {/* <Link to={"/blog/" + blog.id} className="col-12 text-overflow"> */}
      <Link
        to={
          "/blog/" +
          encodeURIComponent(
            props.blog.header
              .replace(/\s+/g, "-")
              .replace(/[^a-zA-Z0-9-_]/g, "")
          )
        }
        className="col-12 text-overflow"
      >
        {props.blog.header} <span className="orange">|</span>{" "}
        <span
          className="stay-updated-item"
          dangerouslySetInnerHTML={{ __html: props.blog.text.substr(0, 3) }}
        ></span>
      </Link>
    </div>
  );
};
