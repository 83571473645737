import "./TickerBar.scss";
import Ticker from "react-ticker";
import { Component } from "react";

export default class TickerBar extends Component {
  constructor(props) {
    super(props);
    this.state = { content: [] };
  }

  async componentDidMount() {
    this.createContent();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.content) {
        this.createContent();
      }
    }
  }

  createContent() {
    let TickerContent = "";
    for (let i = 0; i < this.props.content.length; i++) {
      if (
        this.props.content[i].todaysPick != null &&
        this.props.content[i].todaysPick.trim().length != 0
      ) {
        TickerContent +=
          "  " +
          `<p class="expert-name-ticker">${this.props.content[i].username}</p>` +
          " : " +
          this.props.content[i].todaysPick +
          "\u00A0\u00A0\u00A0\u00A0";
      }
    }
    this.setState({ textContent: TickerContent });
  }

  render() {
    return (
      <div className="row mt-3 mb-2">
        <div className="col-12 TickerBar">
          <h4 className="pr-3"> FREE PICKS: </h4>
          {this.state.textContent ? (
            <Ticker height={45}>
              {() => (
                <p
                  dangerouslySetInnerHTML={{ __html: this.state.textContent }}
                ></p>
              )}
            </Ticker>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
}
