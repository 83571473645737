import React, { useState, useEffect } from "react";
import { useProvideContext } from "../../App";

import "./Ad.scss";

const Ad = (props) => {
  const [ad, setAd] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [cssClass, setCssClass] = useState(null);
  const [type, setType] = useState(props.type);

  const context = useProvideContext();
  // registering the event listener
  // [] indicates that it only run once
  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
  }, []);

  // change type based on screen width, any type
  useEffect(() => {
    if (type === "leaderboard" && screenWidth < 800) {
      setType("bigbox");
    }
    if (type === "bigbox" && screenWidth > 800) {
      setType("leaderboard");
    }
  }, [type, screenWidth]);

  // change ad based on type
  useEffect(() => {
    const filteredAds = props.ads?.filter((item) => item.type === type);
    if (filteredAds) {
      if (props.position) {
        const selectedAd = filteredAds.find(
          (element) => element.position === props.position
        );
        setAd(selectedAd);
      } else {
        // choose random  result
        const randomIndex = Math.floor(Math.random() * filteredAds.length);
        setAd(filteredAds[randomIndex]);
      }
    }
  }, [type, props.position, props.ads]);

  // change css based on type
  useEffect(() => {
    let css = null;
    switch (type) {
      case "bigbox":
        css = "ad-image-bigbox";
        break;
      case "square":
        css = "ad-image-square";
        break;
      case "skyscraper":
        css = "ad-image-skyscraper";
        break;
      case "affiliate":
        css = "ad-image-affiliateLogo";
        break;
      case "leaderboard":
        css = "ad-image-leaderboard";
        break;
      case "random":
        break;
      default:
        break;
    }
    setCssClass(css);
  }, [type]);

  return ad &&
    ((ad.global && context.country !== "US") ||
      (!ad.global && context.country === "US")) ? (
      <a
        href={ad?.url}
        rel="noreferrer"
        target="_blank"
        className="no-decoration"
      >
        {props.type === "affiliate" ? (
          <div className={cssClass}>
            <div className="row mb-2">
              {/* <div className="BackgroundBlur"></div> */}
              <div className="col-12">
                {/* <div className="AffiliateHorizontal mb-2"></div> */}
                <div className="row no-decoration">
                  <div className="col-4 my-auto">
                    <img src={ad?.image[0]?.url} alt="HomeAd" width="200" height="100" />
                  </div>
                  <p className="col-5 my-auto no-decoration">{ad?.text}</p>
                  <button className="my-auto col-3 AffiliateButton no-decoration">
                    Bet NOW!
                </button>
                </div>
                {/* <div className="AffiliateHorizontal mt-2"></div> */}
              </div>
            </div>
          </div>
        ) : (
            <img src={ad?.image[0]?.url} className={cssClass} alt="HomeAd" />
          )}
      </a>
    ) : (
      <div></div>
    );
};

export default Ad;
