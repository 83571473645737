import React, { useState } from "react";
import "./ContactUs.scss";
import CUtext from "../../constants/cu-text";
import { sendContact } from "../../fetchService";
import { useHistory } from "react-router-dom";
import axios from "axios";

export default function ContactUs() {
  window.scrollTo(0, 0);
  let content = fetchContactUs();
  let history = useHistory();
  const blankData = {
    first: "",
    last: "",
    email: "",
    phone: "",
    inquiry: "",
  };
  const [data, setData] = useState(blankData);
  const sendContactForm = (e) => {
    fetch(
      "https://1yccr66ahd.execute-api.ca-central-1.amazonaws.com/SendEmail",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          senderName: "info@headsupentertainment.com",
          senderEmail: "info@headsupentertainment.com",
          inquiry: data.inquiry,
          first: data.first,
          last: data.last,
          phone: data.phone,
          email: data.email,
        }),
      }
    );
    return alert("Contact form submitted!"), history.push("/");
  };

  return (
    <div>
      <div className="cu">
        <h1>Contact Us</h1>
      </div>
      <div className="row text-wrap  white pt-2 pl-1 ">
        <div className="col-6">
          <p>{content.description}</p>
        </div>
        <div className="col-6">
          <h4 className="dark-yellow">{content.addressLine1}</h4>
          <p className="address">{content.addressLine2}</p>
          <p className="address">{content.addressLine3}</p>
          <p className="address">{content.addressLine4}</p>
        </div>
      </div>
      <form className="mt-4" onSubmit={sendContactForm}>
        <div className="row cf">
          <div className="col-md-6">
            <div className="input-group mb-3 mt-4">
              <input
                value={data.first}
                onChange={(e) => setData({ ...data, first: e.target.value })}
                type="text"
                className="form-control"
                placeholder="First Name"
                aria-label="Firstname"
              />
            </div>
          </div>

          <div className="col-md-6 mt-4">
            <input
              value={data.last}
              onChange={(e) => setData({ ...data, last: e.target.value })}
              type="text"
              className="form-control"
              placeholder="Last Name"
              aria-label="Lastname"
            />
          </div>
        </div>

        <div className="row cf">
          <div className="col-md-6">
            <div className="input-group mb-3 mt-4">
              <input
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
                type="text"
                className="form-control"
                placeholder="Email"
                aria-label="Email"
              />
            </div>
          </div>

          <div className="col-md-6 mt-4">
            <input
              value={data.phone}
              onChange={(e) => setData({ ...data, phone: e.target.value })}
              type="text"
              className="form-control"
              placeholder="Phone"
              aria-label="Phone"
            />
          </div>
        </div>

        <div className="row cf">
          <div className="col-md-12 mt-4 ">
            <input
              value={data.inquiry}
              onChange={(e) => setData({ ...data, inquiry: e.target.value })}
              type="text"
              className="form-control"
              placeholder="Inquiry"
              aria-label="Inquiry"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-4 ml-1">
            <button type="submit" className="form-submit ml-1">
              SUBMIT
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

function fetchContactUs() {
  return CUtext;
}
