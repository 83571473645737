import "./RecommendedVideos.scss";
import Post from "../Post/Post";
import { Component } from "react";
import { getRecommendedVideos } from "../../fetchService";

export default class RecommendedVideos extends Component {
  constructor(props) {
    super(props);
    this.state = { videos: [], shownRows: 3 };
  }

  more() {
    this.setState({
      shownRows: this.state.shownRows + 2,
    });
  }

  render() {
    return (
      <div>
        <div className="row justify-content-between">
          {this.props.videos
            ?.slice(0, this.state.shownRows * 4)
            .map((video, id) => (
              video.status === "available"? (
              <div className="col-lg-3 mt-3" key={id}>
                <Post data={video}></Post>
              </div>
              ): ""
            ))}
        </div>
        <div className="d-flex justify-content-center">
          <button className="more-button" onClick={() => this.more()}>
            more
          </button>
        </div>
      </div>
    );
  }
}
