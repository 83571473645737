import MailchimpSubscribe from "react-mailchimp-subscribe"

export default function Mailchimp() {
    const url = "https://headsupentertainment.us7.list-manage.com/subscribe/post?u=959171ea68c8453d971f15202&amp;id=b0860e9115"

    return (
        <div className="col-lg-10 mb-1 pr-2">
            <label htmlFor="subscribe" className="pb-2">Subscribe to our mailing list</label>
            <MailchimpSubscribe url={url}/>
        </div>
    );
}
