import { Fragment } from "react";
import MainVideoPlayer from "../MainVideoPlayer/MainVideoPlayer";
import Post from "../Post/Post";
import StayUpdated from "../StayUpdated/StayUpdated";
import RecentVideos from "../RecentVideos/RecentVideos";
import RecommendedVideos from "../RecommendedVideos/RecommendedVideos";
import Divider from "../Divider/Divider";
import "./Videos.scss";
import React, { Component } from "react";
import { Posts } from "../../constants/posts";
import { withRouter } from "react-router-dom";
import { getVimeoVideo } from "../../fetchService";

class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: null,
      videoId: null,
      posts: null,
      loading: true,
      tag: "",
      open:false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // on change of video
    if (prevProps.location.key !== this.props.location.key) {
      this.setVideo();
    }
    if (prevProps !== this.props) {
      this.setVideo();
    }
  }

  componentDidMount() {
    this.setVideo();
  }

  async setVideo() {
    let video;
    let videoId;
    // if the link has a video id
    if (this.props.match?.params?.videoId) {
      videoId = this.props.match?.params?.videoId;
      video = await getVimeoVideo(videoId);
    } else {
      // default video
      if (this.props.recentVideos) {
        video = this.props.recentVideos[0];
        videoId = video?.uri?.substr(video?.uri?.lastIndexOf("/") + 1);
      }
    }
    this.setState({ video: video, posts: getPosts(), videoId: videoId });
  }

 

  render() {
    return (
      <Fragment>
        <div className="row videos">
          <div className="col-lg-9">
            <MainVideoPlayer video={this.state.video}></MainVideoPlayer>
            <div className="MainVideoDescription">
              <h3 className="Tags d-flex flex-wrap">
                {this.state.video?.tags.map((element, i) => {
                  return <div key={i}>{element.tag}</div>;
                })}
              </h3>

              {/* <h3>#Basketball #SCORES</h3> */}
              <h1>{this.state.video?.name}</h1>
              <h2>{this.state.video?.description}</h2>
            </div>
          </div>
          <div className="col-3 DisableOnMobile">
            <div>
              {this.state.posts ? <Post data={this.state.posts[0]}></Post> : ""}
              <div className="mt-4 mb-4 ">
                <StayUpdated content={this.props.blogs}></StayUpdated>
              </div>
              {this.state.posts ? <Post data={this.state.posts[0]}></Post> : ""}
            </div>
          </div>
        </div>

        <Divider text={"RECENT VIDEOS"}></Divider>
        <div className="row">
          <div className="col-12 pb-4">
            <RecentVideos
              categories={this.props.categories}
              videos={this.props.recentVideos}
            ></RecentVideos>
          </div>
        </div>

        <Divider className="pt-5" text={"RECOMMENDED"}></Divider>
        <div className="row mb-4">
          <div className="col-12">
            <RecommendedVideos
              categories={this.props.categories}
              videos={this.props.recommendedVideos}
            ></RecommendedVideos>
          </div>
        </div>
      </Fragment>
    );
  }
}

function getPosts() {
  return Posts;
}
export default withRouter(Videos);
