import facebook from "../../images/facebook.png";
import twitter from "../../images/twitter.png";
import instagram from "../../images/instagram.png";
import youtube from "../../images/youtube.png";
import "./SocialMediaIcons.scss";

export default function SocialMediaIcons() {
  return (
    <div className="socialMedia">
      <a className="icon" href="https://facebook.com/gamechangerzpvn" target="_blank" rel="noreferrer">
        <img src={facebook} width="30px" className="" alt="" />
      </a>
      <a className="icon" href="https://instagram.com/gamechangerzpvn" target="_blank" rel="noreferrer">
        <img src={instagram} width="30px" className="" alt="" />
      </a>
      <a className="icon" href="https://twitter.com/gamechangerzpvn" target="_blank" rel="noreferrer">
        <img src={twitter} width="30px" className="" alt="" />
      </a>
      <a className="icon" href="https://www.youtube.com/channel/UC6ORt2aQLCwuRqmh7pLBBqg" target="_blank" rel="noreferrer">
        <img src={youtube} width="30px" className="" alt="" />
      </a>
    </div >
  )
}